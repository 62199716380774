

































































































.login-main {
    width: 100%;
    height: 100vh;
    background-color: #F3F3F6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-content {
    height: 579px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .login-left {
        width: 445px;
        height: 100%;
        background-color: #FFF;
        padding: 0px 5px 0px 60px;
        .login-left-main {
            .login-headline {
                width: 100%;
                height: 215px;
                display: flex;
                justify-content: center;
                align-items: center;
                h3 {
                    font-size: 24px;
                    color: #03A293;
                    font-weight: 400;
                }
            }
            .login-form-main {
                width: 100%;
                .form-input-item {
                    ::v-deep input {
                        background-color: #F4F9F9;
                        &:focus {
                            border-color: #03A293;
                        }
                    }
                }
                .form-btn-item {
                    margin-top: 42px;
                    .submit-btn {
                        width: 100%;
                        background-color: #03A293;
                        color: #FFFFFF;
                        &:active {
                            border-color: #03A293;
                        }
                    }
                }
            }
        }
    }
    .login-right {
        background: url("../assets/images/bg.png");
        width: 608px;
        height: 100%;
    }
}


